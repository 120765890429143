(function ( $ ) {
     $.fn.showVideo = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink   = $(this);
        var didScroll = false;

        function _init() {
            if ($(window).width() > 700) {
                str = '<video playsinline="playsinline" autoplay="autoplay" loop="true" muted="muted"><source src="'+objLink.attr('data-src')+'" type="video/mp4"></video>';

                objLink.append( str );
            }else{
                objLink.addClass('notVideo');
            }
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$('#home-slider').showVideo();
$('#home-video').showVideo();
